var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "관람안내",
            "tabActive": "관람안내",
            "bg": "/images/sub/information/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--lg text-center"
  }, [_vm._v("관람안내")])]), _c('div', {
    staticClass: "sub-cont"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("관람안내")])]), _c('ul', {
    staticClass: "sub-list"
  }, [_c('li', [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm"
  }, [_vm._v("관람시간")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "page-text page-text--lg grey--text"
  }, [_c('p', [_vm._v("· 월요일 ~ 토요일 : 오전 10시 ~ 오후 6시")]), _c('p', {
    staticClass: "primary--text ml-8 ml-lg-12"
  }, [_vm._v("※ 입장마감 : 오후 5시")])])])], 1)], 1), _c('li', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm"
  }, [_vm._v("휴관일")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--lg grey--text"
  }, [_vm._v("· 매주 일요일, 법정 공휴일, 근로자의 날")])])], 1)], 1)])]), _c('div', {
    staticClass: "sub-cont"
  }, [_c('div', {
    attrs: {
      "id": "group"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("단체관람안내")])]), _c('ul', {
    staticClass: "sub-list"
  }, [_c('li', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm"
  }, [_vm._v("단체 관람 예약")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "page-text page-text--lg grey--text"
  }, [_c('p', {
    staticClass: "red--text ml-8 ml-lg-12"
  }, [_vm._v("※ 2·4주차 토요일 단체관람 가능")]), _c('p', [_vm._v("· 20명 이상 학생(초 · 중 · 고) 및 성인")]), _c('p', [_vm._v("· 당일 예약 불가, 최소 7일 전 예약 필수 (미예약 단체는 전시 관람이 제한될 수 있음)")]), _c('p', [_vm._v("· 문의 : 055-970-0800")])])])], 1)], 1)])])]), _c('div', {
    staticClass: "sub-cont"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("관람 유의사항")])]), _c('div', {
    staticClass: "border-light pa-12 pa-md-18 pa-lg-24"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-img', {
    staticClass: "mr-6 mr-md-12",
    attrs: {
      "eager": "",
      "max-width": "80",
      "src": "/images/sub/information/information/1.png",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "page-text page-text--lg"
  }, [_vm._v("박물관의 모든 공간은 금연구역입니다.")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-img', {
    staticClass: "mr-6 mr-md-12",
    attrs: {
      "eager": "",
      "max-width": "80",
      "src": "/images/sub/information/information/2.png",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "page-text page-text--lg"
  }, [_vm._v("안내견 이외의 애완동물은 출입할 수 없습니다.")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-img', {
    staticClass: "mr-6 mr-md-12",
    attrs: {
      "eager": "",
      "max-width": "80",
      "src": "/images/sub/information/information/3.png",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "page-text page-text--lg line-height-17"
  }, [_vm._v(" 전시물을 건드리거나 손상을 입힐 수 있는 행위는"), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 삼가주십시오. (체험전시물 제외) ")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-img', {
    staticClass: "mr-6 mr-md-12",
    attrs: {
      "eager": "",
      "max-width": "80",
      "src": "/images/sub/information/information/4.png",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "page-text page-text--lg"
  }, [_vm._v("전시관 내에 음식물을 반입할 수 없습니다.")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-img', {
    staticClass: "mr-6 mr-md-12",
    attrs: {
      "eager": "",
      "max-width": "80",
      "src": "/images/sub/information/information/5.png",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "page-text page-text--lg line-height-17"
  }, [_vm._v(" 전시관 입장 전에 휴대전화는 전원을 꺼주시거나 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 진동으로 전환하여 주십시오. ")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-img', {
    staticClass: "mr-6 mr-md-12",
    attrs: {
      "eager": "",
      "max-width": "80",
      "src": "/images/sub/information/information/6.png",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "page-text page-text--lg line-height-17"
  }, [_vm._v(" 플래쉬, 삼각대 등을 이용한 사진촬영, 상업적 용도의 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 촬영은 금지입니다. ")])], 1)])], 1)], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }